import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Paper,
  StyleRules,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { adminGetAllCompanyVerification } from '../../actions/companyActions';
import DialogTitle from '../../components/DialogTitle';
import CompanyInfoDetailsView from './CompanyInfoDetailsView';
// import { AppState } from '../../reducers';

const styles = (): StyleRules =>
  createStyles({
    DataGridMargin: {
      minHeight: '80vh',
    },
    contentPadding: {
      padding: '10px 0',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      // minHeight: '80vh',
      paddingBottom: '48px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    DialogWidth: {
      minWidth: '850px',
      border: '20px solid red',
    },
    searchInput: {
      margin: '20px',
    },
    backgroundColor: {
      backgroundColor: '#ffffff',
    },
    textTitle: {
      textAlign: 'center',
    },
    multiple: {
      margin: '1rem',
    },
    statusError: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: 'rgb(154, 0, 54)',
    },
    statusFinished: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: '#0069d9',
    },

    statusPublished: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: '#388e3c',
    },

    statusSubmitted: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: '#d5d5d5',
    },
  });

const CompanyListView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const [companyInfoList, setCompanyInfoList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: '企业名称',
      flex: 1,
      renderCell: params => {
        return `${params.row.basicInfo.registrationName}`;
      },
    },
    {
      field: 'status',
      headerName: '审核状态',
      width: 150,
      renderCell: params => {
        return (
          <div>
            {!params.row.status ||
            params.row.status === 'SUBMITTED' ||
            params.row.status === 'NONE' ? (
              <span className={classes.statusSubmitted}>待审核</span>
            ) : null}
            {params.row.status === 'VERIFIED' ? (
              <span className={classes.statusFinished}>已完成</span>
            ) : null}
            {params.row.status === 'DENIED' ? (
              <span className={classes.statusError}>审核失败</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: '操作',
      type: 'number',
      width: 150,
      sortable: false,
      renderCell: params => (
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true);
            setCompanyInfo(params.row);
          }}
        >
          <VisibilityIcon /> 查看
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    const value = await dispatch(adminGetAllCompanyVerification());
    setCompanyInfoList(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.contentPadding}>
      <Paper square className={classes.searchInput}>
        <DataGrid
          rows={companyInfoList}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className={classes.DataGridMargin}
        />
      </Paper>

      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          查看企业信息
        </DialogTitle>
        <DialogContent dividers>
          <CompanyInfoDetailsView
            companyData={companyInfo}
            onClosed={handleClose}
          ></CompanyInfoDetailsView>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(CompanyListView);
